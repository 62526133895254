<template>
  <button type="button" class="btn btn-none ff-link ff-link-icon cta-wishlist" :class="{'active': isOnWishlist, 'btn-secondary btn-whishlist': isButton}" @click.prevent="actionOnWishlist">
    <i class="ff-icon ff-icon-17-wishlist"></i>
  </button>
</template>

<script>
import AddToWishlist from '@/theme/components/AddToWishlist/AddToWishlist'
import AddGiftcardToWishList from '@/components/Product/GiftCard/AddGiftcardToWishList'

export default {
  mixins: [AddGiftcardToWishList, AddToWishlist],
  name: 'AddGiftcardToWishlist',
  methods: {
    addToWishlist () {
      return this.$emit('addToWishlist')
    }
  }
}
</script>
