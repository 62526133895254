import { render, staticRenderFns } from "./QuickView.vue?vue&type=template&id=2dba5991&"
import script from "./QuickView.vue?vue&type=script&lang=js&"
export * from "./QuickView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "QuickView.vue"
export default component.exports