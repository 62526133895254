<template>
  <div class="card-product">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col lg="5">
          <div class="product-media">
            <image-gallery
              v-if="productMedia"
              :imgPath="productMedia.url"
              :hasVideo="(productMedia.video_content) ? productMedia.video_content.video_url : ''"
              size="MD"
              :styleImage="{ maxWidth: '100%'}"
              :changeAble="true"
            />
          </div>
        </b-col>
        <b-col lg="7">
          <div class="product-info">
            <gift-card-form :product="product" @success="$emit('added-to-cart', $event)" />
            <div class="product-info__view-more">
              <div class="product-info__view-more text-center">
                <router-link :to="productLink" class="ff-link">{{ $t('product-details') }}</router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ClassicProduct from '@/theme/components/QuickView/QuickClassicProduct.vue'
import GiftCardForm from '@/theme/components/Product/GiftCard/GiftCardForm'

export default {
  name: 'QuickGiftCardProduct',
  mixins: [ClassicProduct],
  data () {
    return {
      isQuickView: true,
      notifyProcess: false,
      variantSelected: undefined
    }
  },
  computed: {
  },
  components: {
    GiftCardForm
  }
}
</script>
