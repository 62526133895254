<template>
  <div class="card-product home-quick-product">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col md="6">
          <div class="product-media">
            <image-gallery
              v-if="productMedia"
              :imgPath="productMedia.url"
              :hasVideo="(productMedia.video_content) ? productMedia.video_content.video_url : ''"
              size="MD"
              :styleImage="{ maxWidth: '100%'}"
              :changeAble="true"
            />
          </div>
        </b-col>
        <b-col md="6" class="column-info">
          <no-ssr>
            <overlay-scrollbars class="product-info mt-5 mt-md-0" :options="{ paddingAbsolute: false } ">
              <div class="product-info__title">
                <div class="product-attribute mb-3" v-if="showExclusive_online || showNewIn">
                  <div class="text-uppercase exclusive-online product-attribute-text" v-if="showExclusive_online">
                    <span>{{ $t('Exclusive_online') }}</span>
                  </div>
                  <div class="text-uppercase new-in product-attribute-text" v-else-if="showNewIn">
                    <span>{{ $t('New in') }}</span>
                  </div>
                </div>
                <div class="product-name">
                  <p
                    class="text-left title"
                    v-html="product && product.name"
                  />
                  <p>{{product.attributesLabel.aqa_sottotitolo.value}}</p>
                </div>
              </div>
              <div class="product-info__price">
                <div class="wrapp-price">
                  <product-price :product="productSelected" />
                  <span class="kit-of" v-show="productSet">{{ $t('kit-of-{count}', { count: productSet })}}</span>
                </div>
                <span class="vat-included" v-html="vatRules"></span>
              </div>
              <div class="selections" v-if="configurable_options">
                <product-options
                  :key="product.name"
                  :product="product"
                  :filterActive="filterActive"
                  :defaultSelection="forceSizeSelection"
                  :variants="configurable_options"
                  :oneSize="oneSize"
                  @siblingChange="siblingChange"
                  @changeFilter="variantChange"
                ></product-options>
              </div>
              <div v-if="!notifyProcess">
                <div class="product-info__cart-action d-flex align-items-center">
                  <add-to-cart
                  :enableAdd="canAddTo"
                  :sku="getSku"
                  :append="true"
                  :product="product"
                  :variant="getVariantsSelected"
                  @cannotAdd="showErrors"
                  @success="$emit('added-to-cart', $event)"
                  />
                  <add-to-wishlist
                  :isButton="true"
                  :enableAdd="canAddTo"
                  class="btn btn-secondary btn-whishlist ml-1"
                  :product="product"
                  :productSelected="productSelected"
                  :variant="getVariantsSelected"
                  @cannotAdd="showErrors" />
                </div>
              </div>
              <div v-else>
                <notify-me
                  :product="product"
                  :productSelected="productSelected"
                  :variant="getVariantsSelected"
                  :addToWishlist="true"
                  :show-boutique="false"></notify-me>
              </div>
              <div class="product-info__view-more">
                <div class="product-info__view-more text-center">
                  <router-link :to="productLink" class="bcm-link bcm-link-secondary">{{ $t('product-details') }}</router-link>
                </div>
              </div>
            </overlay-scrollbars>
          </no-ssr>
          <!-- <div class="product-info mt-5 mt-md-0">
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ClassicProduct from '@/theme/components/QuickView/QuickClassicProduct.vue'
import HomeProduct from '@/theme/components/Product/Layout/HomeProduct'

export default {
  name: 'QuickHomeProduct',
  mixins: [HomeProduct, ClassicProduct]
}
</script>
