<template>
  <div class="card-product">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col md="6">
          <div class="product-media">
            <image-gallery
              v-if="productMedia"
              :imgPath="productMedia.url"
              :hasVideo="(productMedia.video_content) ? productMedia.video_content.video_url : ''"
              size="MD"
              :styleImage="{ maxWidth: '100%'}"
              :changeAble="true"
            />
          </div>
        </b-col>
        <b-col md="6" class="column-info">
          <no-ssr>
            <overlay-scrollbars class="product-info mt-5 mt-md-0" :options="{ paddingAbsolute: false } ">
              <div class="product-info__title">
                <div class="product-attribute mb-3" v-if="showExclusive_online || showNewIn">
                  <div class="text-uppercase exclusive-online product-attribute-text" v-if="showExclusive_online">
                    <span>{{ $t('Exclusive_online') }}</span>
                  </div>
                  <div class="text-uppercase new-in product-attribute-text" v-else-if="showNewIn">
                    <span>{{ $t('New in') }}</span>
                  </div>
                </div>
                <div class="product-name">
                  <p
                    class="text-left title"
                    v-html="product && product.name"
                  />
                  <p>{{product.attributesLabel.aqa_sottotitolo.value}}</p>
                </div>
              </div>
              <div class="product-info__price">
                <product-price :product="productSelected" />
                <span class="vat-included" v-html="vatRules"></span>
              </div>
              <div class="selections" v-if="configurable_options">
                <product-options
                  :key="product.name"
                  :product="product"
                  :filterActive="filterActive"
                  :defaultSelection="forceSizeSelection"
                  :variants="configurable_options"
                  :oneSize="oneSize"
                  @siblingChange="siblingChange"
                  @changeFilter="variantChange"
                ></product-options>
              </div>
              <div v-if="!notifyProcess">
                <div class="product-info__cart-action d-flex align-items-center">
                  <add-to-cart
                  :enableAdd="canAddTo"
                  :sku="getSku"
                  :append="true"
                  :product="product"
                  :variant="getVariantsSelected"
                  @cannotAdd="showErrors"
                  @success="$emit('added-to-cart', $event)"
                  />
                  <add-to-wishlist
                  :isButton="true"
                  :enableAdd="canAddTo"
                  class="btn btn-secondary btn-whishlist ml-1"
                  :product="product"
                  :productSelected="productSelected"
                  :variant="getVariantsSelected"
                  @cannotAdd="showErrors" />
                </div>
              </div>
              <div v-else>
                <notify-me
                  :product="product"
                  :productSelected="productSelected"
                  :variant="getVariantsSelected"
                  :addToWishlist="true"
                  :show-boutique="false"></notify-me>
              </div>
              <div class="product-info__view-more">
                <div class="product-info__view-more text-center">
                  <router-link :to="productLink" class="bcm-link bcm-link-secondary">{{ $t('product-details') }}</router-link>
                </div>
              </div>
            </overlay-scrollbars>
          </no-ssr>
          <!-- <div class="product-info mt-5 mt-md-0">
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ClassicProduct from '@/theme/components/Product/Layout/ClassicProduct'

import { EventBus } from '@/helpers'
import Config from '@/config'

export default {
  name: 'QuickClassicProduct',
  mixins: [ClassicProduct],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isQuickView: true,
      notifyProcess: false,
      variantSelected: undefined
    }
  },
  computed: {
    productLink () {
      return this.product && this.product.productLink ? this.product.productLink : ''
    },
    vatRules () {
      let content = this.$store.getters['cms/getContent'](Config.Theme.blockTaxRuleID)
      content = content.replace('{taxRate}', `${this.product.tax_rate}%`)
      return content
    },
    productMedia () {
      // get main image
      const { width, height } = Config.Theme.listingOpt.imageCrop
      let media = { ...this.product.image }
      let entries = this.product.media_gallery_entries || []
      // check for video first
      let video = this.imageFiltered.find(gallery => gallery.hasOwnProperty('video_content'))
      if (video) {
        media = { ...video, ...media }
      } else if (entries && entries.length) {
        // find for listing types
        let listingMedia = entries.find((entry) => entry.types.includes('listing'))
        if (listingMedia) {
          media = {
            url: `${this.$store.getters['storeConfig/productPath']}${listingMedia.file}`
          }
        } else {
          media = { ...this.imageFiltered[0][0] }
        }
      }
      media.url = `${media.url}?fit=crop&w=${width}&h=${height}`
      return media
    }
  },
  methods: {
    variantChange (variant) {
      // in this way on color change
      // the size even with a selected value is disabled
      // if (this.filterActive[variant.type] === variant.value) {
      //   this.$delete(this.filterActive, variant.type)
      // } else {
      //   this.$set(this.filterActive, variant.type, variant.value)
      // }
      if (variant.value) {
        this.$set(this.filterActive, variant.type, variant.value)
      } else {
        this.$delete(this.filterActive, variant.type)
      }
      if (variant.isNotificable) {
        this.notifyProcess = true
      } else {
        this.notifyProcess = false
      }
      this.forceSizeSelection[variant.type] = variant.value
    },
    async siblingChange ($event) {
      const _this = this
      this.$store.commit('ui/showLoader')
      return this.$store.dispatch('product/loadByUrlKey', $event.url_key + $event.url_suffix)
        .then(product => {
          EventBus.$emit('quickBuy:resetFilterSize')
          _this.$store.commit('product/loadConfigurable', true)
          _this.$store.dispatch('listing/manageQuickView', product)
          return _this.$store.dispatch('product/loadConfigurableByUrlKey', product.url_key)
            .finally(() => {
              _this.$store.commit('product/loadConfigurable', false)
            })
        })
        .finally(() => {
          _this.sizeSelectorManager()
          _this.$store.commit('ui/hideLoader')
        })
    }
  },
  components: {
    // ImageGallery
  }
}
</script>
